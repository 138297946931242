import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SportService } from 'src/services/sport.service';
import { Sport } from 'src/entities/Sport';

@Component({
  selector: 'app-show-sport-modal',
  templateUrl: './show-sport-modal.component.html',
  styleUrls: ['./show-sport-modal.component.css'],
})
export class ShowSportModalComponent implements OnInit {
  sport: Sport | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sportService: SportService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.sportService
        .getSport(this.data.guid)
        .subscribe((sport: Sport) => {
          this.sport = sport;
        });
    }
  }
}

<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="oddtype; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.name ?? "-" }}
      </h1>
      <div *ngIf="!oddtype">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="oddtype">
        <div style="flex: 1; display: flex">
          <div style="flex: 1">
            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                placeholder="Nom du type de cote"
                formControlName="name"
                required
              />
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Sport</mat-label>
              <mat-select formControlName="sports" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let sport of sports" [value]="sport.guid">
                  {{ sport.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <div>
      <mat-error *ngIf="formGroup.controls['name'].errors">{{
        formGroup.controls["name"].errors?.error
      }}</mat-error>
    </div>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        *ngIf="formGroup.valid"
        [disabled]="submitLoading || !oddtype"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OddtypeService } from 'src/services/oddtype.service';
import { OddType } from 'src/entities/OddType';

@Component({
  selector: 'app-show-oddtype-modal',
  templateUrl: './show-oddtype-modal.component.html',
  styleUrls: ['./show-oddtype-modal.component.css'],
})
export class ShowOddtypeModalComponent implements OnInit {
  oddtype: OddType | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oddtypeService: OddtypeService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.oddtypeService
        .getOddtype(this.data.guid)
        .subscribe((oddtype: OddType) => {
          this.oddtype = oddtype;
        });
    }
  }
}

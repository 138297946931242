import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sport } from 'src/entities/Sport';
import { APIService } from './api.service';

@Injectable()
export class SportService {
  constructor(private apiService: APIService) {}

  public getSports(params: any = null): Observable<Sport[]> {
    return this.apiService.get(`/a/sports`, params, true);
  }
  public getSport(guid: string): Observable<Sport> {
    return this.apiService.get(`/sports/${guid}`);
  }
  public putSport(guid: string, body: Partial<Sport>): Observable<Sport> {
    return this.apiService.put(`/sports/${guid}`, body);
  }
  public createSport(body: Partial<Sport>): Observable<Sport> {
    return this.apiService.post(`/sports`, body);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OddType } from 'src/entities/OddType';
import { OddtypeService } from 'src/services/oddtype.service';
import { SportService} from "../../../../services/sport.service";
import {FormGroup, FormControl, Validators, ValidationErrors} from '@angular/forms';
import {Observable} from "rxjs";
import {Sport} from "../../../../entities/Sport";

@Component({
  selector: 'app-edit-oddtype-modal',
  templateUrl: './edit-oddtype-modal.component.html',
  styleUrls: ['./edit-oddtype-modal.component.css'],
})
export class EditOddtypeModalComponent implements OnInit {
  oddtype: OddType | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    sports: new FormControl(null, [Validators.required]),
  });
  errors: string | null = null;
  sports: Sport[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oddtypeService: OddtypeService,
    private sportService: SportService,
    private dialogRef: MatDialogRef<EditOddtypeModalComponent>
  ) {}

  ngOnInit(): void {
    console.dir(this.data);
    if (this.data.guid) {
      this.oddtypeService
        .getOddtype(this.data.guid)
        .subscribe((oddtype: OddType) => {
          this.oddtype = oddtype;
          console.log(this.oddtype);
          this.formGroup.setValue({
            name: oddtype.name,
            sports: oddtype.sport?.guid ?? null,
          });
        });
    } else {
      this.oddtype = new OddType();
    }

    // Retrieve all sport
    this.sportService.getSports().subscribe((sports: any) => {
      this.sports = sports['hydra:member'];
    });
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    this.errors = null;
    let data: any = Object.assign({}, this.formGroup.value);

    console.log(data);

    if (this.formGroup.valid) {
      // Hardcode data
      data.betsapiId = 1;
      data.sport = '/s/sports/' + data.sports;

      let request: Observable<OddType> = this.data.guid
        ? this.oddtypeService.putOddtype(this.data.guid, data)
        : this.oddtypeService.createOddtype(data);

      request
        .subscribe(
          () => {
            this.closeModal(true);
          },
          (response: any) => {
            console.log(response);
            // Show errors
            response.error.violations.forEach((violation: any) => {
              this.formGroup.controls[violation.propertyPath]?.setErrors({
                error: violation.message,
              });
            });
          }
        )
        // Remove loader when all requests are completed
        .add(() => {
          this.submitLoading = false;
        });
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.formGroup.get(key)?.errors!;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            this.formGroup.controls[key]?.setErrors({
              error: keyError,
            });
          });
        }
      });
    }
  }
}

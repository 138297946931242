import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { AppService } from './app.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class APIService {
  private URL: string = environment.apiURL;
  private URL_SECURE: string = environment.apiSecureURL;
  private token: string | null = null;
  // TODO : implement refresh token
  private refreshToken: string | null = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private appService: AppService
  ) {
    this.token = localStorage.getItem('token');
  }

  /**
   * GET data from API
   */
  get(
    path: string,
    params: any = null,
    isSecure: boolean = true
  ): Observable<any> {
    let url: string = isSecure ? this.URL_SECURE : this.URL;
    url += path;
    url += this.paramsToString(params);

    console.log(`[GET] request : ${url}`);

    return this.http.get(url, { headers: this.getHeaders(isSecure) });
  }

  /**
   * POST data in API
   */
  post(path: string, body: any, isSecure: boolean = true): Observable<any> {
    let url: string = isSecure ? this.URL_SECURE : this.URL;
    url += path;

    console.log(`[POST] request : ${url}`);

    return this.http.post(url, body, { headers: this.getHeaders(isSecure) });
  }

  /**
   * PUT data in API
   */
  put(path: string, body: any, isSecure: boolean = true): Observable<any> {
    let url: string = isSecure ? this.URL_SECURE : this.URL;
    url += path;

    console.log(`[PUT] request : ${url}`);

    return this.http.put(url, body, { headers: this.getHeaders(isSecure) });
  }

  /**
   * PUT data in API
   */
  patch(path: string, body: any, isSecure: boolean = true): Observable<any> {
    let url: string = isSecure ? this.URL_SECURE : this.URL;
    url += path;

    let headers: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/merge-patch+json')
      // .append('Accept', 'application/json')
      .append(
        'Authorization',
        isSecure && this.token ? `Bearer ${this.token}` : ''
      )
      .append('Accept-Language', this.appService.getAppLanguage());

    console.log(`[PATCH] request : ${url}`);

    return this.http.patch(url, body, { headers: headers });
  }

  /**
   * DELETE data in API
   */
  delete(path: string, isSecure: boolean = true): Observable<any> {
    let url: string = isSecure ? this.URL_SECURE : this.URL;
    url += path;

    console.log(`[DELETE] request : ${url}`);

    return this.http.delete(url, { headers: this.getHeaders(isSecure) });
  }

  /**
   * HTTP Headers
   */
  private getHeaders(isSecure: boolean): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/json')
      // .append('Accept', 'application/json')
      .append(
        'Authorization',
        isSecure && this.token ? `Bearer ${this.token}` : ''
      )
      .append('Accept-Language', this.appService.getAppLanguage())
      .append('Content-Language', this.appService.getAppLanguage());

    return headers;
  }

  /**
   * Convert an object of params into a formatted HTTP string suffix version
   */
  public paramsToString(params: any): string {
    if (typeof params === 'undefined' || !params) return '';

    let result = '';

    Object.keys(params).forEach((key: string, index: number) => {
      result += `${index === 0 ? '?' : '&'}${key}=${params[key]}`;
    });
    return result;
  }

  /**
   * Check if the user is connected
   */
  public isUserConnected(): boolean {
    return this.token !== null;
  }

  /**
   * Decode token and retrieve guid
   */
  public getCurrentUserGuid(): string | null {
    if (this.token) {
      return (jwt_decode(this.token) as { guid: string }).guid;
    }
    return null;
  }

  /**
   * Login user
   */
  public login(values: { email: string; password: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.post('/login_check', values, false).subscribe(
        (response: any) => {
          if (response && response.token && response.refresh_token) {
            this.token = response.token;
            this.refreshToken = response.refresh_token;
            localStorage.setItem('token', response.token);
            resolve();
          }
        },
        (err: any) => {
          console.log('error : ', err);
          reject(err);
        }
      );
    });
  }


  /**
   * Logout user
   */
  public logout(): void {
    localStorage.removeItem('token');
    this.token = null;
    this.router.navigate(['login']);
  }

  /**
   * Retrieve the current username
   */
  getCurrentUsername(): string | null {
    if (this.token) {
      const decoded: any = jwt_decode(this.token);
      return decoded['email'];
    } else {
      return null;
    }
  }
}

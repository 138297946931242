import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';
import { Event } from 'src/entities/Event';
import {League} from "../entities/League";

@Injectable()
export class EventService {
  constructor(private apiService: APIService) {}

  public getEvents(params: any = null): Observable<Event[]> {
    return this.apiService.get(`/a/events`, params, true);
  }
  public getEvent(guid: string): Observable<Event> {
    return this.apiService.get(`/events/${guid}`);
  }
  public putEvent(guid: string, body: Partial<Event>): Observable<Event> {
    return this.apiService.put(`/events/${guid}`, body);
  }
  public createEvent(body: Partial<Event>): Observable<Event> {
    return this.apiService.post(`/events`, body);
  }
}

<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="competitor; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.name ?? "-" }}
      </h1>
      <div *ngIf="!competitor">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="competitor">
        <div style="flex: 1; display: flex">
          <div style="flex: 1">
            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                placeholder="Nom du compétiteur"
                formControlName="name"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <div>
      <mat-error *ngIf="formGroup.controls['name'].errors">{{
        formGroup.controls["name"].errors?.error
      }}</mat-error>
    </div>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        *ngIf="formGroup.valid"
        [disabled]="submitLoading || !competitor"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

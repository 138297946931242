<router-outlet>
  <mat-dialog-content class="mat-typography">
    <ul>
      Exemple :
      <li>
        Sport: Koddz lanta
      </li>
      <li>
        Ligue: Koh lanta All-star 2021
      </li>
      <li>
        Compétiteur: N/A
      </li>
      <li>
        Match: Ep. 1
      </li>
      <li>
        Type de cote: Epreuve de confort
      </li>
      <li>
        Cote: 1.25 Equipe Jaune / 1.48 Equipe rouge
      </li>
    </ul>
  </mat-dialog-content>
</router-outlet>

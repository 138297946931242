import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';
import {Status} from "../entities/Status";

@Injectable()
export class StatusService {
  constructor(private apiService: APIService) {}

  public getStatuses(params: any = null): Observable<Status[]> {
    return this.apiService.get(`/statuses`, params, true);
  }
  public getStatus(guid: string): Observable<Status> {
    return this.apiService.get(`/statuses/${guid}`);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Odd } from 'src/entities/Odd';
import { APIService } from './api.service';

@Injectable()
export class OddService {
  constructor(private apiService: APIService) {}

  public getOdds(params: any = null): Observable<Odd[]> {
    return this.apiService.get(`/a/odds`, params, true);
  }
  public getOdd(guid: string): Observable<Odd> {
    return this.apiService.get(`/odds/${guid}`);
  }
  public putOdd(guid: string, body: Partial<Odd>): Observable<Odd> {
    return this.apiService.put(`/odds/${guid}`, body);
  }
  public createOdd(body: Partial<Odd>): Observable<Odd> {
    return this.apiService.post(`/odds`, body);
  }
}

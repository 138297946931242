import { Component } from '@angular/core';
import { APIService } from 'src/services/api.service';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'BetsioAdmin';
  currentUsername: string | null;
  constructor(private apiService: APIService) {
    this.currentUsername = apiService.getCurrentUsername();
    console.log("Running Betsio admin panel in "+environment.name+" environnement");
  }

  logout(): void {
    this.apiService.logout();
  }

  isUserConnected(): boolean {
    return this.apiService.isUserConnected();
  }
}

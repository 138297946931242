import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from 'src/services/event.service';
import { Event } from 'src/entities/Event';

@Component({
  selector: 'app-show-event-modal',
  templateUrl: './show-event-modal.component.html',
  styleUrls: ['./show-event-modal.component.css'],
})
export class ShowEventModalComponent implements OnInit {
  event: Event | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.eventService
        .getEvent(this.data.guid)
        .subscribe((event: Event) => {
          this.event = event;
        });
    }
  }
}

<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="user; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/admin/users/' + user.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ user?.username ?? "" }}
    </h1>
    <div *ngIf="!user">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="user">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Nom d'utilisateur : </strong>{{ user.username }}</p>
          <p><strong>E-mail : </strong>{{ user.email }}</p>
          <p>
            <strong>Date de naisssance : </strong>{{ user.birthdate | date }}
          </p>
          <p><strong>Utilisateur demo : </strong>{{ user.isDemoUser }}</p>
          <p><strong>Utilisateur secret : </strong>{{ user.isSecret }}</p>
        </div>
        <div style="flex: 1">
          <p>
            <strong>Nb d'arènes freemium (créateur) : </strong
            >{{ user.createdContestsFreemiumCount }}
          </p>
          <p>
            <strong>Nb d'arènes premium (créateur) : </strong
            >{{ user.createdContestsPremiumCount }}
          </p>
          <p>
            <strong>Nb d'arènes freemium (participant) : </strong
            >{{ user.participantContestsFreemiumCount }}
          </p>
          <p>
            <strong>Nb d'arènes premium (participant) : </strong
            >{{ user.participantContestsPremiumCount }}
          </p>
          <p>
            <strong>Nb de paris créés : </strong>{{ user.createdBetsCount }}
          </p>
          <p>
            <strong>Nb de bonus récupérés : </strong
            >{{ user.retrievedBonusesCount }}
          </p>
          <p *ngIf="user.lastBetAt">
            <strong>Dernier pari : </strong
            >{{ user.lastBetAt | date: "medium" }}
          </p>
          <p *ngIf="user.lastUnlockedBonusAt">
            <strong>Dernier bonus récupéré : </strong
            >{{ user.lastUnlockedBonusAt | date: "medium" }}
          </p>
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>

    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>


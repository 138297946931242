import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-copy-entity-data',
  templateUrl: './copy-entity-data.component.html',
  styleUrls: ['./copy-entity-data.component.css'],
})
export class CopyEntityDataComponent {
  @Input() data: string = '';

  constructor(private snackBar: MatSnackBar) {}

  getData(): string {
    return this.data;
  }

  copiedDataSuccess(): void {
    this.snackBar.open('Copié!', undefined, {
      duration: 1000,
    });
  }
}

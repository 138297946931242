import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OddType } from 'src/entities/OddType';
import { APIService } from './api.service';

@Injectable()
export class OddtypeService {
  constructor(private apiService: APIService) {}

  public getOddtypes(params: any = null): Observable<OddType[]> {
    return this.apiService.get(`/a/odd_types`, params, true);
  }
  public getOddtype(guid: string): Observable<OddType> {
    return this.apiService.get(`/odd_types/${guid}`);
  }
  public putOddtype(guid: string, body: Partial<OddType>): Observable<OddType> {
    return this.apiService.put(`/odd_types/${guid}`, body);
  }
  public createOddtype(body: Partial<OddType>): Observable<OddType> {
    return this.apiService.post(`/odd_types`, body);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/entities/User';
import { APIService } from './api.service';

@Injectable()
export class UserService {
  constructor(private apiService: APIService) {}

  public getUsers(params: any = null): Observable<User[]> {
    return this.apiService.get(`/users`, params, true);
  }
  public getUser(guid: string): Observable<User> {
    return this.apiService.get(`/users/${guid}`);
  }

  public banUser(guid: string): Observable<User> {
    return this.apiService.put(`/users/${guid}`, { isLocked: true });
  }

  public unbanUser(guid: string): Observable<User> {
    return this.apiService.put(`/users/${guid}`, { isLocked: false });
  }

  public deleteUser(guid: string): Observable<void> {
    return this.apiService.delete(`/users/${guid}`);
  }
}

<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="event; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.name ?? "-" }}
      </h1>
      <div *ngIf="!event">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="event">
        <div style="flex: 1; display: flex">
          <div style="flex: 1">
            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                placeholder="Nom du match"
                formControlName="name"
                required
              />
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Sport</mat-label>
              <mat-select formControlName="sport" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let sport of sports" [value]="sport.guid">
                  {{ sport.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Date du match</mat-label>
              <input
                matInput
                [matDatepicker]="playAtPicker"
                formControlName="playAt"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="playAtPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #playAtPicker></mat-datepicker>
              <mat-error *ngIf="formGroup.controls['playAt']?.errors">{{
                formGroup.controls["playAt"]?.errors?.error
                }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Heure du match</mat-label>
              <input
                matInput
                placeholder="Heure du match"
                formControlName="time"
                required
                type="time"
              />
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Ligue</mat-label>
              <mat-select formControlName="league" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let league of leagues" [value]="league.guid">
                  {{ league.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Domicile</mat-label>
              <mat-select formControlName="home" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let home of competitors" [value]="home.guid">
                  {{ home.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Extérieur</mat-label>
              <mat-select formControlName="away" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let away of competitors" [value]="away.guid">
                  {{ away.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let status of statuses" [value]="status.guid">
                  {{ status.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        *ngIf="formGroup.valid"
        [disabled]="submitLoading || !event"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

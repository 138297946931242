import { Component } from '@angular/core';

@Component({
  selector: 'app-unban-user-modal',
  templateUrl: './unban-user-modal.component.html',
  styleUrls: ['./unban-user-modal.component.css'],
})
export class UnbanUserModalComponent {
  constructor() {}
}

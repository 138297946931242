<mat-dialog-content class="mat-typography">
  <h3>Bannir un utilisateur</h3>
  <p>
    Voulez-vous vraiment bannir cet utilisateur ? Il existera toujours mais ne
    pourra plus se connecter à l'application.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Bannir</button>
</mat-dialog-actions>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { League } from 'src/entities/League';
import { APIService } from './api.service';

@Injectable()
export class LeagueService {
  constructor(private apiService: APIService) {}

  public getLeagues(params: any = null): Observable<League[]> {
    return this.apiService.get(`/a/leagues`, params, true);
  }
  public getLeague(guid: string): Observable<League> {
    return this.apiService.get(`/leagues/${guid}`);
  }
  public putLeague(guid: string, body: Partial<League>): Observable<League> {
    return this.apiService.put(`/leagues/${guid}`, body);
  }
  public createLeague(body: Partial<League>): Observable<League> {
    return this.apiService.post(`/leagues`, body);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { League } from 'src/entities/League';
import { LeagueService } from 'src/services/league.service';
import { ShowLeagueModalComponent } from './show-league-modal/show-league-modal.component';
import { EditLeagueModalComponent} from './edit-league-modal/edit-league-modal.component';

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.css'],
})
export class LeaguesComponent implements OnInit {
  data: League[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = ['guid', 'name', 'actions'];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  constructor(
    private leagueService: LeagueService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowLeagueModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Retrieve data from API
    this.leagueService.getLeagues(this.getParams()).subscribe(
      (response: any) => {
        // Set all data
        this.data = response['hydra:member'];
        // Set the total count for the paginator
        this.totalItems = response['hydra:totalItems'];
      },
      () => null,
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['name'] = this.searchedValue;

    return params;
  }

  /**
   * Find a league in the data by guid
   */
  getLeagueByGuid(guid: string): League | null {
    const index: number = this.data.findIndex(
      (league: League) => league.guid === guid
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(league: any): void {
    // Clear the order if no header is sorted
    if (league.active === '' || league.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: league.active,
        direction: league.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(league: any): void {
    this.searchedValue = (league.target as HTMLTextAreaElement).value;
    this.page = 1; // Reset page
    this.setData(false);
  }

  /**
   * Show the show league modal
   */
  showLeagueModal(guid: string): void {
    console.log('show league : ', guid);
    const league: League | null = this.getLeagueByGuid(guid);
    if (league) {
      this.dialog.open(ShowLeagueModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: league.guid,
        },
      });
    }
  }

  /**
   * Show the edit league modal
   */
  editLeagueModal(guid: string | null = null): void {
    console.log('edit league : ', guid);
    let league: League | null = null;

    if (guid) {
      league = this.getLeagueByGuid(guid);
      if (!league) return;
    } else {
      league = new League();
    }

    if (league) {
      const dialogRef = this.dialog.open(EditLeagueModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: league.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }
}

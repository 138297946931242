<div style="margin-top: 20px">
  <h1 class="mat-display-1 text-center">Cote</h1>
</div>

<section>
  <div *ngIf="!loading; else loader" style="width: 100%">
    <div class="text-center">
      <!-- Search user by username -->
      <mat-form-field appearance="fill">
        <mat-label>Recherche</mat-label>
        <input
          matInput
          type="search"
          [(ngModel)]="searchedValue"
          (input)="setSearchedValue($event)"
        />
        <button
          *ngIf="searchedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchedValue = ''; this.setData(false)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div style="margin: 20px 10px; float: right">
      <button
        matSuffix
        mat-raised-button
        color="primary"
        (click)="editOddModal()"
      >
        Nouvelle cote
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        *ngIf="data"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- GUID -->
        <ng-container matColumnDef="guid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.guid | truncate:[8, ''] }}</td>
        </ng-container>

        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Value -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Valeur</th>
          <td mat-cell *matCellDef="let element">{{ element.value }}</td>
        </ng-container>

        <!-- Event -->
        <ng-container matColumnDef="event">
          <th mat-header-cell *matHeaderCellDef>Match</th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-dialog-close
              [routerLink]="['/events', element.event?.guid ?? '']"
            >{{ element.event?.name }}</a
            >
          </td>
        </ng-container>


        <!-- OddType -->
        <ng-container matColumnDef="oddtype">
          <th mat-header-cell *matHeaderCellDef>Type de cote</th>
          <td mat-cell *matCellDef="let element">
            <a
              mat-dialog-close
              [routerLink]="['/oddtypes', element.oddType?.guid ?? '']"
            >{{ element.oddType?.name }}</a
            >
          </td>
        </ng-container>

        <!-- Match -->
        <ng-container matColumnDef="match">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Match</th>
          <td mat-cell *matCellDef="let element">{{ element.match.name }}</td>
        </ng-container>

        <!-- Result -->
        <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Résultat</th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip
              [color]="getChipColorFromResult(element)"
              [tooltip]="getChipTooltipFromResult(element)"
            ></app-status-chip>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showOddModal(element.guid)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
              <div
                (click)="editOddModal(element.guid)"
                class="cursor_pointer"
                matTooltip="Editer"
              >
                <mat-icon>edit</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        showFirstLastButtons
        [pageSize]="40"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

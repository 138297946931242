import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Event, getNameFromEventStatus, EventStatus } from 'src/entities/Event';
import { EventService } from 'src/services/event.service';
import { ShowEventModalComponent } from './show-event-modal/show-event-modal.component';
import { EditEventModalComponent} from './edit-event-modal/edit-event-modal.component';
import { ChipColor } from 'src/app/status-chip/status-chip.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit {
  data: Event[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = ['guid', 'name', 'league', 'sport', 'status', 'playAt', 'actions'];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  constructor(
    private eventService: EventService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowEventModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Retrieve data from API
    this.eventService.getEvents(this.getParams()).subscribe(
      (response: any) => {
        // Set all data
        this.data = response['hydra:member'];
        // Set the total count for the paginator
        this.totalItems = response['hydra:totalItems'];
      },
      () => null,
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['name'] = this.searchedValue;

    return params;
  }

  /**
   * Find a event in the data by guid
   */
  getEventByGuid(guid: string): Event | null {
    const index: number = this.data.findIndex(
      (event: Event) => event.guid === guid
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(event: any): void {
    // Clear the order if no header is sorted
    if (event.active === '' || event.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: event.active,
        direction: event.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(event: any): void {
    this.searchedValue = (event.target as HTMLTextAreaElement).value;
    this.page = 1; // Reset page
    this.setData(false);
  }

  /**
   * Show the show event modal
   */
  showEventModal(guid: string): void {
    console.log('show event : ', guid);
    const event: Event | null = this.getEventByGuid(guid);
    if (event) {
      this.dialog.open(ShowEventModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: event.guid,
        },
      });
    }
  }

  /**
   * Show the edit event modal
   */
  editEventModal(guid: string | null = null): void {
    console.log('edit event : ', guid);
    let event: Event | null = null;

    if (guid) {
      event = this.getEventByGuid(guid);
      if (!event) return;
    } else {
      event = new Event();
    }

    if (event) {
      const dialogRef = this.dialog.open(EditEventModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: event.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }

  getChipColorFromStatus(event: Event): ChipColor {
    switch (event.status.name) {
      case EventStatus.STATUS_NOT_STARTED:
        return ChipColor.ORANGE;
      case EventStatus.STATUS_INPLAY:
        return ChipColor.BLUE;
      case EventStatus.STATUS_TOBEFIXED:
      case EventStatus.STATUS_POSTPONED:
      case EventStatus.STATUS_CANCELLED:
      case EventStatus.STATUS_WALKOVER:
      case EventStatus.STATUS_INTERRUPTED:
      case EventStatus.STATUS_ABANDONNED:
      case EventStatus.STATUS_RETIRED:
      case EventStatus.STATUS_REMOVED:
        return ChipColor.RED;
      case EventStatus.STATUS_ENDED:
        return ChipColor.BLACK;
      default:
        return ChipColor.GREY;
    }
  }

  getChipTooltipFromStatus(event: Event): string {
    return getNameFromEventStatus(event.status);
  }
}

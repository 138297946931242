import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Supplier } from 'src/entities/Supplier';
import { APIService } from './api.service';

@Injectable()
export class SupplierService {
  constructor(private apiService: APIService) {}

  public getSuppliers(params: any = null): Observable<Supplier[]> {
    return this.apiService.get(`/s/suppliers`, params, true);
  }
  public getSupplier(guid: string): Observable<Supplier> {
    return this.apiService.get(`/suppliers/${guid}`);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Competitor } from 'src/entities/Competitor';
import { CompetitorService } from 'src/services/competitor.service';
import {FormGroup, FormControl, Validators, ValidationErrors} from '@angular/forms';
import {Observable} from "rxjs";

@Component({
  selector: 'app-edit-competitor-modal',
  templateUrl: './edit-competitor-modal.component.html',
  styleUrls: ['./edit-competitor-modal.component.css'],
})
export class EditCompetitorModalComponent implements OnInit {
  competitor: Competitor | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
  });
  errors: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private competitorService: CompetitorService,
    private dialogRef: MatDialogRef<EditCompetitorModalComponent>
  ) {}

  ngOnInit(): void {
    console.dir(this.data);
    if (this.data.guid) {
      this.competitorService
        .getCompetitor(this.data.guid)
        .subscribe((competitor: Competitor) => {
          this.competitor = competitor;
          console.log(this.competitor);
          this.formGroup.setValue({
            name: competitor.name
          });
        });
    } else {
      this.competitor = new Competitor();
    }
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    this.errors = null;
    let data: any = Object.assign({}, this.formGroup.value);

    if (this.formGroup.valid) {
      // Hardcode data
      data.betsapiId = 1;
      data.supplier = '/s/suppliers/2';

      let request: Observable<Competitor> = this.data.guid
        ? this.competitorService.putCompetitor(this.data.guid, data)
        : this.competitorService.createCompetitor(data);

      request
        .subscribe(
          () => {
            this.closeModal(true);
          },
          (response: any) => {
            console.log(response);
            // Show errors
            response.error.violations.forEach((violation: any) => {
              this.formGroup.controls[violation.propertyPath]?.setErrors({
                error: violation.message,
              });
            });
          }
        )
        // Remove loader when all requests are completed
        .add(() => {
          this.submitLoading = false;
        });
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.formGroup.get(key)?.errors!;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            this.formGroup.controls[key]?.setErrors({
              error: keyError,
            });
          });
        }
      });
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AuthGuard } from './helpers/auth.gard';
import { LoginComponent } from './login/login.component';
import { LeaguesComponent } from './admin/leagues/leagues.component';
import { EventsComponent } from './admin/events/events.component';
import { CompetitorsComponent } from './admin/competitors/competitors.component';
import { SportsComponent } from './admin/sports/sports.component';
import { OddtypesComponent } from './admin/oddtypes/oddtypes.component';
import { OddsComponent } from './admin/odds/odds.component';

const routes: Routes = [
  // Anonymous user
  { path: 'login', component: LoginComponent },

  // Connected user
  { path: '', redirectTo: '', pathMatch: 'full' },

  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'competitors', component: CompetitorsComponent },
      { path: 'competitors/:guid', component: CompetitorsComponent },
      { path: 'sports', component: SportsComponent },
      { path: 'sports/:guid', component: SportsComponent },
      { path: 'oddtypes', component: OddtypesComponent },
      { path: 'oddtypes/:guid', component: OddtypesComponent },
      { path: 'odds', component: OddsComponent },
      { path: 'odds/:guid', component: OddsComponent },
      { path: 'leagues', component: LeaguesComponent },
      { path: 'leagues/:guid', component: LeaguesComponent },
      { path: 'events', component: EventsComponent },
      { path: 'events/:guid', component: EventsComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

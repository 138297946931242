import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OddType } from 'src/entities/OddType';
import { OddtypeService } from 'src/services/oddtype.service';
import { ShowOddtypeModalComponent } from './show-oddtype-modal/show-oddtype-modal.component';
import { EditOddtypeModalComponent} from './edit-oddtype-modal/edit-oddtype-modal.component';

@Component({
  selector: 'app-oddtypes',
  templateUrl: './oddtypes.component.html',
  styleUrls: ['./oddtypes.component.css'],
})
export class OddtypesComponent implements OnInit {
  data: OddType[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = ['guid', 'name', 'sport', 'actions'];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  constructor(
    private oddtypeService: OddtypeService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowOddtypeModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Retrieve data from API
    this.oddtypeService.getOddtypes(this.getParams()).subscribe(
      (response: any) => {
        // Set all data
        this.data = response['hydra:member'];
        // Set the total count for the paginator
        this.totalItems = response['hydra:totalItems'];
      },
      () => null,
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['name'] = this.searchedValue;

    return params;
  }

  /**
   * Find a oddtype in the data by guid
   */
  getOddtypeByGuid(guid: string): OddType | null {
    const index: number = this.data.findIndex(
      (oddtype: OddType) => oddtype.guid === guid
    );

    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(oddtype: any): void {
    // Clear the order if no header is sorted
    if (oddtype.active === '' || oddtype.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: oddtype.active,
        direction: oddtype.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(oddtype: any): void {
    this.searchedValue = (oddtype.target as HTMLTextAreaElement).value;
    this.page = 1; // Reset page
    this.setData(false);
  }

  /**
   * Show the show oddtype modal
   */
  showOddtypeModal(guid: string): void {
    console.log('show oddtype : ', guid);
    const oddtype: OddType | null = this.getOddtypeByGuid(guid);
    if (oddtype) {
      this.dialog.open(ShowOddtypeModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: oddtype.guid,
        },
      });
    }
  }

  /**
   * Show the edit oddtype modal
   */
  editOddtypeModal(guid: string | null = null): void {
    console.log('edit oddtype : ', guid);
    let oddtype: OddType | null = null;

    if (guid) {
      oddtype = this.getOddtypeByGuid(guid);
      if (!oddtype) return;
    } else {
      oddtype = new OddType();
    }

    if (oddtype) {
      const dialogRef = this.dialog.open(EditOddtypeModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: oddtype.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { APIService } from 'src/services/api.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { NotFoundComponent } from './not-found/not-found.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { UserService } from 'src/services/user.service';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { UnbanUserModalComponent } from './admin/users/unban-user-modal/unban-user-modal.component';
import { ShowUserModalComponent } from './admin/users/show-user-modal/show-user-modal.component';
import { DeleteUserModalComponent } from './admin/users/delete-user-modal/delete-user-modal.component';
import { BanUserModalComponent } from './admin/users/ban-user-modal/ban-user-modal.component';
import { UsersComponent } from './admin/users/users.component';
import { LeaguesComponent } from './admin/leagues/leagues.component';
import { EventsComponent } from './admin/events/events.component';
import { ShowEventModalComponent } from './admin/events/show-event-modal/show-event-modal.component';
import { EditEventModalComponent} from "./admin/events/edit-event-modal/edit-event-modal.component";
import { LeagueService } from 'src/services/league.service';
import { EventService } from 'src/services/event.service';
import { ShowLeagueModalComponent } from './admin/leagues/show-league-modal/show-league-modal.component';
import { EditLeagueModalComponent} from "./admin/leagues/edit-league-modal/edit-league-modal.component";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AppService } from 'src/services/app.service';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CopyEntityLinkComponent } from './copy-entity-link/copy-entity-link.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CompetitorService } from 'src/services/competitor.service';
import { ShowCompetitorModalComponent } from './admin/competitors/show-competitor-modal/show-competitor-modal.component';
import { CompetitorsComponent } from './admin/competitors/competitors.component';
import { EditCompetitorModalComponent } from './admin/competitors/edit-competitor-modal/edit-competitor-modal.component';
import {SportsComponent} from "./admin/sports/sports.component";
import {ShowSportModalComponent} from "./admin/sports/show-sport-modal/show-sport-modal.component";
import {EditSportModalComponent} from "./admin/sports/edit-sport-modal/edit-sport-modal.component";
import {SportService} from "../services/sport.service";
import {OddtypesComponent} from "./admin/oddtypes/oddtypes.component";
import {ShowOddtypeModalComponent} from "./admin/oddtypes/show-oddtype-modal/show-oddtype-modal.component";
import {EditOddtypeModalComponent} from "./admin/oddtypes/edit-oddtype-modal/edit-oddtype-modal.component";
import {OddtypeService} from "../services/oddtype.service";
import {OddService} from "../services/odd.service";
import {OddsComponent} from "./admin/odds/odds.component";
import {ShowOddModalComponent} from "./admin/odds/show-odd-modal/show-odd-modal.component";
import {EditOddModalComponent} from "./admin/odds/edit-odd-modal/edit-odd-modal.component";
import { TruncatePipe } from "./helpers/truncate";
import {SupplierService} from "../services/supplier.service";
import {CopyEntityDataComponent} from "./copy-entity-data/copy-entity-data.component";
import {StatusService} from "../services/status.service";

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NotFoundComponent,
    UsersComponent,
    BanUserModalComponent,
    DeleteUserModalComponent,
    ShowUserModalComponent,
    UnbanUserModalComponent,
    LoginComponent,
    AdminComponent,
    StatusChipComponent,
    LeaguesComponent,
    EventsComponent,
    ShowEventModalComponent,
    EditEventModalComponent,
    ShowLeagueModalComponent,
    EditLeagueModalComponent,
    LanguageSelectorComponent,
    CopyEntityLinkComponent,
    CompetitorsComponent,
    ShowCompetitorModalComponent,
    EditCompetitorModalComponent,
    SportsComponent,
    ShowSportModalComponent,
    EditSportModalComponent,
    OddtypesComponent,
    ShowOddtypeModalComponent,
    EditOddtypeModalComponent,
    OddsComponent,
    ShowOddModalComponent,
    EditOddModalComponent,
    TruncatePipe,
    CopyEntityDataComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatTreeModule,
    MatGridListModule,
    MatCardModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    ClipboardModule,
    MatSnackBarModule,
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    APIService,
    UserService,
    LeagueService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    EventService,
    AppService,
    CompetitorService,
    SportService,
    OddtypeService,
    OddService,
    SupplierService,
    StatusService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

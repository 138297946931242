<div style="margin-top: 20px">
  <h1 class="mat-display-1 text-center">Utilisateurs</h1>
</div>

<section>
  <div *ngIf="!loading; else loader" style="width: 100%">
    <div class="text-center">
      <!-- Search user by username -->
      <mat-form-field appearance="fill">
        <mat-label>Recherche</mat-label>
        <input
          matInput
          type="search"
          [(ngModel)]="searchedValue"
          (input)="setSearchedValue($event)"
        />
        <button
          *ngIf="searchedValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchedValue = ''; this.setData(false)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div>
      <table
        mat-table
        [dataSource]="data"
        matSort
        class="mat-elevation-z8"
        *ngIf="data"
        (matSortChange)="this.sortChange($event)"
      >
        <!-- ID -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- Username -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
          <td mat-cell *matCellDef="let element">{{ element.username }}</td>
        </ng-container>

        <!-- Email -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <!-- Birthdate -->
        <ng-container matColumnDef="birthdate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Date de naissance
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.birthdate | date }}
          </td>
        </ng-container>

        <!-- isDemoUser -->
        <ng-container matColumnDef="isDemoUser">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>isDemoUser</th>
          <td mat-cell *matCellDef="let element">{{ element.isDemoUser }}</td>
        </ng-container>

        <!-- isLocked -->
        <ng-container matColumnDef="isLocked">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activé</th>
          <td mat-cell *matCellDef="let element">
            <app-status-chip [bool]="!element.isLocked"></app-status-chip>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-evenly">
              <div
                (click)="showUserModal(element.id)"
                class="cursor_pointer"
                matTooltip="Afficher"
              >
                <mat-icon>visibility</mat-icon>
              </div>
              <div
                (click)="banUserModal(element.id)"
                class="cursor_pointer"
                [matTooltip]="element.isLocked ? 'Débannir' : 'Bannir'"
                *ngIf="element.guid !== currentUserGuid"
              >
                <mat-icon>{{
                  element.isLocked
                    ? "check_circle_outline"
                    : "remove_circle_outline"
                }}</mat-icon>
              </div>
              <div
                (click)="deleteUserModal(element.id)"
                class="cursor_pointer"
                matTooltip="Supprimer"
                *ngIf="element.id !== currentUserGuid"
              >
                <mat-icon class="disabled_text">delete</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator
        showFirstLastButtons
        [pageSize]="40"
        [length]="totalItems"
        (page)="page = $event.pageIndex + 1; setData(false)"
      >
      </mat-paginator>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

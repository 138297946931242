import { Component, OnInit } from '@angular/core';

type SideNavItemType = {
  title: string;
  link: string;
  children?: SideNavItemType[];
  isActive?: boolean;
  isOpened?: boolean;
};

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  sideNavShown: boolean = true;
  sideNavItems: SideNavItemType[] = [];

  constructor() {}

  ngOnInit(): void {
    this.sideNavItems = this.getSideNavItems();
  }

  toggleSideNav(): void {
    this.sideNavShown = !this.sideNavShown;
  }

  getSideNavItems() {
    const sidenavItems: SideNavItemType[] = [
      {
        title: 'Dashboard',
        link: '/dashboard',
      },
      {
        title: 'Sports',
        link: '/sports',
      },
      {
        title: 'Ligues',
        link: '/leagues',
      },
      {
        title: 'Compétiteurs',
        link: '/competitors',
      },
      {
        title: 'Matchs',
        link: '/events',
      },
      {
        title: 'Types de cote',
        link: '/oddtypes',
      },
      {
        title: 'Cotes',
        link: '/odds',
      },
    ];
    return sidenavItems;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Event, EventStatus} from 'src/entities/Event';
import { EventService } from 'src/services/event.service';
import {FormGroup, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {Observable} from "rxjs";
import {League} from "../../../../entities/League";
import {min} from "rxjs/operators";
import {CompetitorService} from "../../../../services/competitor.service";
import {OddType} from "../../../../entities/OddType";
import {Competitor} from "../../../../entities/Competitor";
import {LeagueService} from "../../../../services/league.service";
import {OddResult} from "../../../../entities/Odd";
import {StatusService} from "../../../../services/status.service";
import {Status} from "../../../../entities/Status";
import {Sport} from "../../../../entities/Sport";
import {SportService} from "../../../../services/sport.service";

@Component({
  selector: 'app-edit-event-modal',
  templateUrl: './edit-event-modal.component.html',
  styleUrls: ['./edit-event-modal.component.css'],
})
export class EditEventModalComponent implements OnInit {
  event: Event | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    playAt: new FormControl(null, [Validators.required]),
    time: new FormControl(null, [Validators.required]),
    home: new FormControl(null, [Validators.required]),
    away: new FormControl(null, [Validators.required]),
    league: new FormControl(null, [Validators.required]),
    status: new FormControl(null, [Validators.required]),
    sport: new FormControl(null, [Validators.required]),
  });
  errors: string | null = null;
  competitors: Competitor[] = [];
  leagues: League[] = [];
  statuses: Status[] = [];
  sports: Sport[] = [];
  // status: { name: string; value: EventStatus }[] = [
  //   { name: 'Non commencé', value: EventStatus.STATUS_NOT_STARTED },
  //   { name: 'Annulé', value: EventStatus.STATUS_CANCELLED },
  //   { name: 'Terminé', value: EventStatus.STATUS_ENDED },
  // ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService,
    private competitorService: CompetitorService,
    private leagueService: LeagueService,
    private statusService: StatusService,
    private sportService: SportService,
    private dialogRef: MatDialogRef<EditEventModalComponent>
  ) {}

  ngOnInit(): void {
    console.dir(this.data);
    if (this.data.guid) {
      this.eventService
        .getEvent(this.data.guid)
        .subscribe((event: Event) => {
          this.event = event;
          console.log(this.event);
          this.formGroup.setValue({
            name: event.name,
            playAt: event.playAt,
            time: this.getTimeFromDatetime(event.playAt),
            home: event.home?.guid ?? null,
            away: event.away?.guid ?? null,
            league: event.league?.guid ?? null,
            status: event.status?.guid ?? null,
            sport: event.sport?.guid ?? null,
          });
        });
    } else {
      this.event = new Event();
    }

    // Retrieve all competitors
    this.competitorService.getCompetitors().subscribe((competitors: any) => {
      this.competitors = competitors['hydra:member'];
    });

    // Retrieve all leagues
    this.leagueService.getLeagues().subscribe((leagues: any) => {
      this.leagues = leagues['hydra:member'];
    });

    // Retrieve all statuses
    this.statusService.getStatuses().subscribe((statuses: any) => {
      this.statuses = statuses['hydra:member'];
    });

    // Retrieve all sports
    this.sportService.getSports().subscribe((sports: any) => {
      this.sports = sports['hydra:member'];
    });
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    this.errors = null;
    let data: any = Object.assign({}, this.formGroup.value);

    if (this.formGroup.valid) {
      // Hardcode data
      data.betsapiId = 1;
      data.timex = ""+ Date.now() + "";
      data.home = '/s/competitors/' + data.home;
      data.away = '/s/competitors/' + data.away;
      data.league = '/s/leagues/' + data.league;
      data.status = '/s/statuses/' + data.status;
      data.sport = '/s/sports/' + data.sport;

      data.supplier = '/s/suppliers/2';

      // Update playAt(datetime) with hour/min from time input
      const playAt = new Date(data.playAt);
      const time = data.time.split(':');
      playAt.setHours(parseInt(time[0]) + 2);
      playAt.setMinutes(time[1]);
      data.playAt = playAt;
      delete data.time;

      let request: Observable<Event> = this.data.guid
        ? this.eventService.putEvent(this.data.guid, data)
        : this.eventService.createEvent(data);

      request
        .subscribe(
          () => {
            this.closeModal(true);
          },
          (response: any) => {
            console.log(response);
            // Show errors
            response.error.violations.forEach((violation: any) => {
              this.formGroup.controls[violation.propertyPath]?.setErrors({
                error: violation.message,
              });
            });
          }
        )
        // Remove loader when all requests are completed
        .add(() => {
          this.submitLoading = false;
        });
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.formGroup.get(key)?.errors!;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            this.formGroup.controls[key]?.setErrors({
              error: keyError,
            });
          });
        }
      });
    }
  }

  getTimeFromDatetime(datetime: string): string {
    const transformDate = new Date(datetime);
    const hours = ("0" + transformDate.getHours()).slice(-2);
    const minutes = ("0" + transformDate.getMinutes()).slice(-2);
    return hours + ':' + minutes;
  }
}

import { Component, Inject, OnInit, LOCALE_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Odd, OddResult} from 'src/entities/Odd';
import { OddService } from 'src/services/odd.service';
import {FormGroup, FormControl, Validators, ValidationErrors} from '@angular/forms';
import {Observable} from "rxjs";
import { OddtypeService } from 'src/services/oddtype.service';
import { OddType } from "../../../../entities/OddType";
import {EventService} from "../../../../services/event.service";
import { Event } from "../../../../entities/Event";
import { formatNumber } from "@angular/common";

@Component({
  selector: 'app-edit-odd-modal',
  templateUrl: './edit-odd-modal.component.html',
  styleUrls: ['./edit-odd-modal.component.css'],
})
export class EditOddModalComponent implements OnInit {
  odd: Odd | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    value: new FormControl(null, [Validators.min(1.01), Validators.max(500)]),
    odd_types: new FormControl(null, [Validators.required]),
    events: new FormControl(null, [Validators.required]),
    result: new FormControl(null, [Validators.required]),
  });
  errors: string | null = null;
  odd_types: OddType[] = [];
  events: Event[] = [];
  results: { name: string; value: OddResult }[] = [
    { name: 'En attente', value: OddResult.RESULT_WAITING },
    { name: 'Gagné', value: OddResult.RESULT_WON },
    { name: 'Perdu', value: OddResult.RESULT_LOST },
    { name: 'Annulé', value: OddResult.RESULT_CANCELLED },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(LOCALE_ID) private locale: string,
    private oddService: OddService,
    private oddTypeService: OddtypeService,
    private eventService: EventService,
    private dialogRef: MatDialogRef<EditOddModalComponent>
  ) {}

  ngOnInit(): void {
    console.dir(this.data);
    if (this.data.guid) {
      this.oddService
        .getOdd(this.data.guid)
        .subscribe((odd: Odd) => {
          this.odd = odd;
          console.log(this.odd);
          this.formGroup.setValue({
            name: odd.name,
            value: odd.value,
            odd_types: odd.oddType?.guid ?? null,
            events: odd.event?.guid ?? null,
            result: odd.result,
          });
        });
    } else {
      this.odd = new Odd();
    }

    // Retrieve all odd_type
    this.oddTypeService.getOddtypes().subscribe((odd_types: any) => {
      this.odd_types = odd_types['hydra:member'];
    });
    // Retrieve all events
    this.eventService.getEvents().subscribe((events: any) => {
      this.events = events['hydra:member'];
    });
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    this.errors = null;
    let data: any = Object.assign({}, this.formGroup.value);
    console.log(data);
    if (this.formGroup.valid) {
      // Hardcode data
      data.betsapiId = 1;
      data.oddType = '/s/odd_types/' + data.odd_types;
      data.event = '/s/events/' + data.events;
      delete data.odd_types;
      delete data.events;

      //Transform value with comma to dot
      data.value = parseFloat(formatNumber(data.value, this.locale,'1.2-2'));
      console.log(data);
      let request: Observable<Odd> = this.data.guid
        ? this.oddService.putOdd(this.data.guid, data)
        : this.oddService.createOdd(data);

      request
        .subscribe(
          () => {
            this.closeModal(true);
          },
          (response: any) => {
            console.log(response);
            // Show errors
            response.error.violations.forEach((violation: any) => {
              this.formGroup.controls[violation.propertyPath]?.setErrors({
                error: violation.message,
              });
            });
          }
        )
        // Remove loader when all requests are completed
        .add(() => {
          this.submitLoading = false;
        });
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.formGroup.get(key)?.errors!;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            this.formGroup.controls[key]?.setErrors({
              error: keyError,
            });
          });
        }
      });
    }

  }
}

<mat-toolbar color="primary">
  <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="toggleSideNav()">
        <mat-icon>menu</mat-icon>
    </button> -->

  <div style="flex: 1; display: flex; justify-content: space-between">
    <span>Gestion Betsio</span>

    <div *ngIf="isUserConnected()">
      <span style="margin-right: 20px">{{ currentUsername }}</span>
      <mat-icon
        (click)="logout()"
        class="cursor_pointer"
        style="vertical-align: sub"
        >logout</mat-icon
      >
    </div>
  </div>
</mat-toolbar>
<router-outlet></router-outlet>

import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APIService } from 'src/services/api.service';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent {
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: APIService,
    private snackBar: MatSnackBar
  ) {
    // create the form
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.apiService
      .login({ email: this.f.email.value, password: this.f.password.value })
      .then(
        (data) => {
          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          console.log(error);
          if (error.status === 401)
            this.snackBar.open('Identifiants incorrect !', 'Fermer', {
              duration: 3000,
            });

          if (error.status === 500)
            this.snackBar.open("Une erreur s'est produite", 'Fermer', {
              duration: 3000,
            });

          this.loading = false;
        }
      );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompetitorService } from 'src/services/competitor.service';
import { Competitor } from 'src/entities/Competitor';

@Component({
  selector: 'app-show-competitor-modal',
  templateUrl: './show-competitor-modal.component.html',
  styleUrls: ['./show-competitor-modal.component.css'],
})
export class ShowCompetitorModalComponent implements OnInit {
  competitor: Competitor | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private competitorService: CompetitorService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.competitorService
        .getCompetitor(this.data.guid)
        .subscribe((competitor: Competitor) => {
          this.competitor = competitor;
        });
    }
  }
}

<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="event; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/events/' + event.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ event?.name ?? "" }}
    </h1>
    <div *ngIf="!event">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="event">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Guid : </strong>
            {{ event.guid }}
            <app-copy-entity-data
              [data]="'' + event.guid"
            ></app-copy-entity-data>
          </p>
          <p><strong>Nom : </strong>{{ event.name }}</p>
          <p><strong>Joué le : </strong>{{ event.playAt | date: 'dd MMMM yyyy à h:mm' }}</p>
          <p><strong>Domicile : </strong>{{ event.home?.name }}</p>
          <p><strong>Extérieur : </strong>{{ event.away?.name }}</p>
          <p><strong>League : </strong>{{ event.league?.name }}</p>
          <p><strong>Status : </strong>{{ event.status.name }}</p>
          <p><strong>Sport : </strong>{{ event.sport?.name }}</p>
<!--          <p><strong>Crée le : </strong>{{ event.createdAt|date }}</p>-->
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

<mat-sidenav-container class="example-container" style="height: 100">
  <mat-sidenav mode="side" [opened]="true" style="width: 20%">
    <mat-nav-list>
      <ng-container *ngFor="let sideNavItem of sideNavItems">
        <ng-container
          *ngIf="sideNavItem.children && sideNavItem.children.length > 0"
        >
          <a
            mat-list-item
            [routerLink]="sideNavItem.link"
            [routerLinkActive]="['active']"
          >
            <span>{{ sideNavItem.title }}</span>
          </a>
          <a
            mat-list-item
            [routerLink]="childItem.link"
            [routerLinkActive]="['active']"
            *ngFor="let childItem of sideNavItem.children"
          >
            <span> - {{ childItem.title }}</span>
          </a>
        </ng-container>
        <a
          mat-list-item
          [routerLink]="sideNavItem.link"
          [routerLinkActive]="['active']"
          *ngIf="!sideNavItem.children || sideNavItem.children.length === 0"
        >
          <span>{{ sideNavItem.title }}</span>
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="min-height: calc(100vh - 64px)">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

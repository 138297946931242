export class Odd {
  id: number | null = null;
  guid: string | null = null;
  name: string | null = null;
  value: number | null = null;
  header: string | null = null;

  oddType: any = [];
  result: any = [];
  event: any = [];
}

export interface OddType {
  name: string;
}

export const enum OddResult {
  RESULT_WAITING = 0,
  RESULT_WON = 1,
  RESULT_LOST = 2,
  RESULT_CANCELLED = 3,
}

export function getNameFromOddResult(oddResult: OddResult): string {
  switch (oddResult) {
    case OddResult.RESULT_CANCELLED:
      return 'Annulé';
    case OddResult.RESULT_WAITING:
      return 'En attente';
    case OddResult.RESULT_WON:
      return 'Gagné';
    case OddResult.RESULT_LOST:
      return 'Perdu';
    default:
      return '-';
  }
}

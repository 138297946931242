import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeagueService } from 'src/services/league.service';
import { League } from 'src/entities/League';

@Component({
  selector: 'app-show-league-modal',
  templateUrl: './show-league-modal.component.html',
  styleUrls: ['./show-league-modal.component.css'],
})
export class ShowLeagueModalComponent implements OnInit {
  league: League | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leagueService: LeagueService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.leagueService
        .getLeague(this.data.guid)
        .subscribe((league: League) => {
          this.league = league;
        });
    }
  }
}

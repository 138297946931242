import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Competitor } from 'src/entities/Competitor';
import { CompetitorService } from 'src/services/competitor.service';
import { ShowCompetitorModalComponent } from './show-competitor-modal/show-competitor-modal.component';
import { EditCompetitorModalComponent} from './edit-competitor-modal/edit-competitor-modal.component';
import {Event} from "../../../entities/Event";
import {EditEventModalComponent} from "../events/edit-event-modal/edit-event-modal.component";

@Component({
  selector: 'app-competitors',
  templateUrl: './competitors.component.html',
  styleUrls: ['./competitors.component.css'],
})
export class CompetitorsComponent implements OnInit {
  data: Competitor[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = ['guid', 'name', 'country', 'createdAt', 'actions'];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  constructor(
    private competitorService: CompetitorService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowCompetitorModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Retrieve data from API
    this.competitorService.getCompetitors(this.getParams()).subscribe(
      (response: any) => {
        // Set all data
        this.data = response['hydra:member'];
        // Set the total count for the paginator
        this.totalItems = response['hydra:totalItems'];
      },
      () => null,
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['name'] = this.searchedValue;

    return params;
  }

  /**
   * Find a competitor in the data by guid
   */
  getCompetitorByGuid(guid: string): Competitor | null {
    const index: number = this.data.findIndex(
      (competitor: Competitor) => competitor.guid === guid
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(competitor: any): void {
    // Clear the order if no header is sorted
    if (competitor.active === '' || competitor.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: competitor.active,
        direction: competitor.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(competitor: any): void {
    this.searchedValue = (competitor.target as HTMLTextAreaElement).value;
    this.page = 1; // Reset page
    this.setData(false);
  }

  /**
   * Show the show competitor modal
   */
  showCompetitorModal(guid: string): void {
    console.log('show competitor : ', guid);
    const competitor: Competitor | null = this.getCompetitorByGuid(guid);
    if (competitor) {
      this.dialog.open(ShowCompetitorModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: competitor.guid,
        },
      });
    }
  }

  /**
   * Show the edit competitor modal
   */
  editCompetitorModal(guid: string | null = null): void {
    console.log('edit competitor : ', guid);
    let competitor: Competitor | null = null;

    if (guid) {
      competitor = this.getCompetitorByGuid(guid);
      if (!competitor) return;
    } else {
      competitor = new Competitor();
    }

    if (competitor) {
      const dialogRef = this.dialog.open(EditCompetitorModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: competitor.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }
}

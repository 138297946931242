import { Component, EventEmitter, Output } from '@angular/core';
import { AppLanguages, AppService } from 'src/services/app.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
})
export class LanguageSelectorComponent {
  @Output() languageChanged = new EventEmitter<AppLanguages>();

  constructor(private appService: AppService) {}

  getAppLanguage(): AppLanguages {
    return this.appService.getAppLanguage();
  }
  getAvailableLanguages(): AppLanguages[] {
    return this.appService.getAvailableLanguages();
  }
  changeLanguage(newLanguage: AppLanguages): void {
    this.appService.changeLanguage(newLanguage);
    this.languageChanged.emit(newLanguage);
  }
  getEmojiFromLanguage(code: string): string {
    return this.appService.getFlagByCountryCode(code);
  }
}

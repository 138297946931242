import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Sport } from 'src/entities/Sport';
import { SportService } from 'src/services/sport.service';
import { ShowSportModalComponent } from './show-sport-modal/show-sport-modal.component';
import { EditSportModalComponent} from './edit-sport-modal/edit-sport-modal.component';

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.css'],
})
export class SportsComponent implements OnInit {
  data: Sport[] = [];
  loading: boolean = false;
  searchedValue: string = '';
  // Columns to display
  displayedColumns: string[] = ['guid', 'name', 'actions'];
  totalItems: number = 0;
  page: number = 1;
  orderBy: { field: string; direction: string } | null = null;
  MODAL_WIDTH: string = '100%';

  constructor(
    private sportService: SportService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    // Show user if needed
    if (this.activatedRoute.snapshot.params['guid']) {
      this.dialog.open(ShowSportModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: this.activatedRoute.snapshot.params['guid'],
        },
      });
    }
  }

  ngOnInit(): void {
    // Set all data
    this.setData();
  }

  /**
   * Retrieve all data
   */
  setData(showLoader: boolean = true): void {
    this.loading = showLoader;

    // Retrieve data from API
    this.sportService.getSports(this.getParams()).subscribe(
      (response: any) => {
        // Set all data
        this.data = response['hydra:member'];
        // Set the total count for the paginator
        this.totalItems = response['hydra:totalItems'];
      },
      () => null,
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Retrieve the params for the API
   */
  getParams(): any {
    let params: any = {};

    // Retrieve order by if it is set
    if (this.orderBy) {
      params[`order[${this.orderBy.field}]`] = this.orderBy.direction;
    }

    params['page'] = this.page;
    params['name'] = this.searchedValue;

    return params;
  }

  /**
   * Find a sport in the data by guid
   */
  getSportByGuid(guid: string): Sport | null {
    const index: number = this.data.findIndex(
      (sport: Sport) => sport.guid === guid
    );
    if (index !== -1) {
      return this.data[index];
    }
    return null;
  }

  /**
   * Change the order by val and reset the pagination on sort change
   */
  sortChange(sport: any): void {
    // Clear the order if no header is sorted
    if (sport.active === '' || sport.direction === '') {
      this.orderBy = null;
    } else {
      // Change the order
      this.orderBy = {
        field: sport.active,
        direction: sport.direction,
      };
    }
    // Reset the pagination
    this.page = 1;

    // Set all data
    this.setData(false);
  }

  setSearchedValue(sport: any): void {
    this.searchedValue = (sport.target as HTMLTextAreaElement).value;
    this.page = 1; // Reset page
    this.setData(false);
  }

  /**
   * Show the show sport modal
   */
  showSportModal(guid: string): void {
    console.log('show sport : ', guid);
    const sport: Sport | null = this.getSportByGuid(guid);
    if (sport) {
      this.dialog.open(ShowSportModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: sport.guid,
        },
      });
    }
  }

  /**
   * Show the edit sport modal
   */
  editSportModal(guid: string | null = null): void {
    console.log('edit sport : ', guid);
    let sport: Sport | null = null;

    if (guid) {
      sport = this.getSportByGuid(guid);
      if (!sport) return;
    } else {
      sport = new Sport();
    }

    if (sport) {
      const dialogRef = this.dialog.open(EditSportModalComponent, {
        width: this.MODAL_WIDTH,
        data: {
          guid: sport.guid,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // Refresh if changes has been made
        if (result?.hasBeenChanged) this.setData();
      });
    }
  }
}

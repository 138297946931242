import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChipColor } from 'src/app/status-chip/status-chip.component';
import { User } from 'src/entities/User';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-show-user-modal',
  templateUrl: './show-user-modal.component.html',
  styleUrls: ['./show-user-modal.component.css'],
})
export class ShowUserModalComponent implements OnInit {
  user: User | null = null;

  displayedColumns: string[] = ['username', 'status'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.userService.getUser(this.data.guid).subscribe((user: User) => {
        this.user = user;
      });
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OddService } from 'src/services/odd.service';
import { Odd, getNameFromOddResult } from 'src/entities/Odd';

@Component({
  selector: 'app-show-odd-modal',
  templateUrl: './show-odd-modal.component.html',
  styleUrls: ['./show-odd-modal.component.css'],
})
export class ShowOddModalComponent implements OnInit {
  odd: Odd | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oddService: OddService
  ) {}

  ngOnInit(): void {
    if (this.data.guid) {
      this.oddService
        .getOdd(this.data.guid)
        .subscribe((odd: Odd) => {

          odd.result = getNameFromOddResult(odd.result);
          this.odd = odd;
        });
    }
  }
}

<form [formGroup]="formGroup" (ngSubmit)="submitLoading ? null : onSubmit()">
  <ng-container *ngIf="odd; else loader">
    <mat-dialog-content class="mat-typography" style="width: 100%">
      <h1 class="mat-display-1" style="text-align: center">
        {{ formGroup.value.name ?? "-" }}
      </h1>
      <div *ngIf="!odd">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="odd">
        <div style="flex: 1; display: flex">
          <div style="flex: 1">
            <mat-form-field appearance="fill">
              <mat-label>Match</mat-label>
              <mat-select formControlName="events" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let event of events" [value]="event.guid">
                  {{ event.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Type de cote</mat-label>
              <mat-select formControlName="odd_types" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let odd_type of odd_types" [value]="odd_type.guid">
                  {{ odd_type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Nom</mat-label>
              <input
                matInput
                placeholder="Nom de la côte"
                formControlName="name"
                required
              />
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Cote</mat-label>
              <input
                matInput
                placeholder="Valeur de la côte"
                type="number"
                formControlName="value"
                id="value"
                required
              />
              <mat-error *ngIf="formGroup.controls['value'].errors">
                Valeur de la cote : Minium 1.01, maximum: 500
              </mat-error>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill">
              <mat-label>Résultat</mat-label>
              <mat-select formControlName="result" required>
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let result of results" [value]="result.value">
                  {{ result.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Retour</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        *ngIf="formGroup.valid"
        [disabled]="submitLoading || !odd"
      >
        Enregistrer
      </button>
    </mat-dialog-actions>
  </ng-container>
</form>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

import { Component, Input, OnInit } from '@angular/core';

export const enum ChipColor {
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  BLACK = 'black',
  GREY = 'grey',
}
@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.css'],
})
export class StatusChipComponent implements OnInit {
  @Input() color: ChipColor | null = null;
  @Input() bool: boolean | null = null;
  @Input() tooltip: string | null = null;

  constructor() {}

  ngOnInit(): void {
    if (this.bool !== null) {
      this.color = this.bool ? ChipColor.GREEN : ChipColor.RED;
    }
  }
}

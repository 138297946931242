<div
  style="
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
  "
>
  <mat-card style="padding: 0">
    <mat-card-title style="text-align: center; margin-top: 25px" class="mat-h1"
      >Connexion</mat-card-title
    >
    <mat-card-content style="padding: 25px 80px 50px 80px">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field>
          <mat-label>Adresse mail</mat-label>
          <input matInput type="text" formControlName="email" />
        </mat-form-field>
        <br />
        <mat-form-field>
          <mat-label>Mot de passe</mat-label>
          <input matInput type="password" formControlName="password" />
        </mat-form-field>

        <div class="form-group">
          <button
            [disabled]="loading"
            class="btn btn-primary"
            mat-raised-button
            color="primary"
            style="width: 100%"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            Envoyer
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { League } from 'src/entities/League';
import { LeagueService } from 'src/services/league.service';
import {FormGroup, FormControl, Validators, ValidationErrors} from '@angular/forms';
import {Observable} from "rxjs";

@Component({
  selector: 'app-edit-league-modal',
  templateUrl: './edit-league-modal.component.html',
  styleUrls: ['./edit-league-modal.component.css'],
})
export class EditLeagueModalComponent implements OnInit {
  league: League | null = null;
  submitLoading: boolean = false;
  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
  });
  errors: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leagueService: LeagueService,
    private dialogRef: MatDialogRef<EditLeagueModalComponent>
  ) {}

  ngOnInit(): void {
    console.dir(this.data);
    if (this.data.guid) {
      this.leagueService
        .getLeague(this.data.guid)
        .subscribe((league: League) => {
          this.league = league;
          console.log(this.league);
          this.formGroup.setValue({
            name: league.name
          });
        });
    } else {
      this.league = new League();
    }
  }

  closeModal(hasBeenChanged: boolean): void {
    this.dialogRef.close({ hasBeenChanged: hasBeenChanged });
  }

  onSubmit(): void {
    this.submitLoading = true;
    this.errors = null;
    let data: any = Object.assign({}, this.formGroup.value);

    if (this.formGroup.valid) {
      // Hardcode data
      data.betsapiId = 1;
      data.supplier = '/s/suppliers/2';

      let request: Observable<League> = this.data.guid
        ? this.leagueService.putLeague(this.data.guid, data)
        : this.leagueService.createLeague(data);
      request
        .subscribe(
          () => {
            this.closeModal(true);
          },
          (response: any) => {
            console.log(response);
            // Show errors
            response.error.violations.forEach((violation: any) => {
              this.formGroup.controls[violation.propertyPath]?.setErrors({
                error: violation.message,
              });
            });
          }
        )
        // Remove loader when all requests are completed
        .add(() => {
          this.submitLoading = false;
        });
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.formGroup.get(key)?.errors!;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            this.formGroup.controls[key]?.setErrors({
              error: keyError,
            });
          });
        }
      });
    }
  }
}

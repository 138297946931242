<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="competitor; else loader">
    <div style="float: right">
      <app-copy-entity-link
        [link]="'/competitors/' + competitor.guid"
      ></app-copy-entity-link>
    </div>
    <h1 class="mat-display-1" style="text-align: center">
      {{ competitor?.name ?? "" }}
    </h1>
    <div *ngIf="!competitor">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="competitor">
      <div style="flex: 1; display: flex">
        <div style="flex: 1">
          <p><strong>Guid : </strong>
            {{ competitor.guid }}
            <app-copy-entity-data
              [data]="'' + competitor.guid"
            ></app-copy-entity-data>
          </p>
          <p><strong>Nom : </strong>{{ competitor.name }}</p>
          <p><strong>Crée le : </strong>{{ competitor.createdAt|date }}</p>
          <p><strong>Pays : </strong>{{ competitor.country?.name }}</p>
        </div>
      </div>
      <mat-divider style="margin: 10px 0"></mat-divider>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Retour</button>
</mat-dialog-actions>

<ng-template #loader>
  <div class="spinner-wrapper">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
